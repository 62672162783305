import { I18n } from '@lingui/core';
import * as yup from 'yup';
import { NumberSchema } from 'yup';

import { numberNormalizer } from 'utils';

/**
 * This function returns the base schema for amortization years
 * @param i18n I18n instance
 * @returns The base schema for amortization years
 */
export const getBaseAmortizationYearsSchema = (i18n: I18n) =>
    yup
        .number()
        .integer(i18n._({ id: 'error.wholeNumber' }))
        .min(1, i18n._({ id: 'errors.minAmortizationYears' }))
        .isValueNaN()
        .nullable();

/**
 * This function returns the base schema for amortization months
 * @param i18n I18n instance
 * @returns The base schema for amortization months
 */
export const getBaseAmortizationMonthsSchema = (i18n: I18n) =>
    yup
        .number()
        .default(0)
        .integer(i18n._({ id: 'error.wholeNumber' }))
        .min(0, i18n._({ id: 'errors.minAmortizationMonths' }))
        .max(11, i18n._({ id: 'errors.maxAmortizationMonths' }))
        .isValueNaN()
        .nullable();

/**
 * This function returns the schema for amortization years
 * @param i18n I18n instance
 * @returns The schema for amortization years
 */
export const getAmortizationYearsSchema = (i18n: I18n) =>
    getBaseAmortizationYearsSchema(i18n).when(
        [
            '$originallyInsured',
            '$amortizationInsuredMaxValue',
            '$amortizationUninsuredMaxValue',
        ],
        // @ts-ignore
        (
            originallyInsured: boolean,
            amortizationInsuredMaxValue: string,
            amortizationUninsuredMaxValue: string,
            schema: NumberSchema
        ) => {
            const normalizedInsuredMaxAmortization = numberNormalizer(
                amortizationInsuredMaxValue
            );

            const normalizedUninsuredMaxAmortization = numberNormalizer(
                amortizationUninsuredMaxValue
            );

            if (originallyInsured) {
                return schema.max(
                    normalizedInsuredMaxAmortization,
                    i18n._({
                        id: 'errors.maxAmortizationPeriodValue',
                        values: { value: normalizedInsuredMaxAmortization },
                    })
                );
            }

            return schema.max(
                normalizedUninsuredMaxAmortization,
                i18n._({
                    id: 'errors.maxAmortizationPeriodValue',
                    values: { value: normalizedUninsuredMaxAmortization },
                })
            );
        }
    );

/**
 * This function returns the schema for amortization months
 * @param i18n I18n instance
 * @returns The schema for amortization months
 */
export const getAmortizationMonthsSchema = (i18n: I18n) =>
    getBaseAmortizationMonthsSchema(i18n).when(
        [
            'amortizationYears',
            '$originallyInsured',
            '$amortizationInsuredMaxValue',
            '$amortizationUninsuredMaxValue',
        ],
        // @ts-ignore
        (
            amortizationYears: number,
            originallyInsured: boolean,
            amortizationInsuredMaxValue: string,
            amortizationUninsuredMaxValue: string,
            schema: NumberSchema
        ) => {
            const normalizedInsuredMaxAmortization = numberNormalizer(
                amortizationInsuredMaxValue
            );

            const normalizedUninsuredMaxAmortization = numberNormalizer(
                amortizationUninsuredMaxValue
            );

            const amortizationYearsWithinLimit =
                amortizationYears < normalizedInsuredMaxAmortization;

            if (originallyInsured && !amortizationYearsWithinLimit) {
                return schema.max(
                    0,
                    i18n._({
                        id: 'errors.maxAmortizationPeriodValue',
                        values: { value: normalizedInsuredMaxAmortization },
                    })
                );
            }

            if (!originallyInsured && !amortizationYearsWithinLimit) {
                return schema.max(
                    0,
                    i18n._({
                        id: 'errors.maxAmortizationPeriodValue',
                        values: { value: normalizedUninsuredMaxAmortization },
                    })
                );
            }

            return schema;
        }
    );

/**
 * This function returns the schema for the qualification amortization years
 * @param i18n I18n instance
 * @returns The schema for the qualification amortization years
 */
export const getQualificationAmortizationYearsSchema = (i18n: I18n) =>
    getBaseAmortizationYearsSchema(i18n).when(
        '$qualificationAmortizationMaxValue',
        (qualificationAmortizationMaxValue: string, schema: NumberSchema) => {
            const normalizedMaxAmortization = numberNormalizer(
                qualificationAmortizationMaxValue
            );

            return schema.max(
                normalizedMaxAmortization,
                i18n._({
                    id: 'errors.maxAmortizationPeriodValue',
                    values: { value: normalizedMaxAmortization },
                })
            );
        }
    );

/**
 * This function returns the schema for the qualification amortization months
 * @param i18n I18n instance
 * @returns The schema for the qualification amortization months
 */
export const getQualificationAmortizationMonthsSchema = (i18n: I18n) =>
    getBaseAmortizationMonthsSchema(i18n).when(
        ['amortizationYears', '$qualificationAmortizationMaxValue'],
        // @ts-ignore
        (
            amortizationYears: number,
            qualificationAmortizationMaxValue: string,
            schema: NumberSchema
        ) => {
            const normalizedMaxAmortization = numberNormalizer(
                qualificationAmortizationMaxValue
            );

            const amortizationYearsWithinLimit =
                amortizationYears < normalizedMaxAmortization;

            if (!amortizationYearsWithinLimit) {
                return schema.max(
                    0,
                    i18n._({
                        id: 'errors.maxAmortizationPeriodValue',
                        values: { value: normalizedMaxAmortization },
                    })
                );
            }

            return schema;
        }
    );

/**
 * This function returns the schema for subject property amortization years
 * @param i18n I18n instance
 * @returns The schema for subject property amortization years
 */
export const getSubjectPropertyAmortizationYearsSchema = (i18n: I18n) =>
    getBaseAmortizationYearsSchema(i18n).when(
        [
            'insuranceQuestionOriginallyInsured',
            '$amortizationInsuredMaxValue',
            '$amortizationUninsuredMaxValue',
        ],
        // @ts-ignore
        (
            insuranceQuestionOriginallyInsured: 'YES' | 'NO',
            amortizationInsuredMaxValue: string,
            amortizationUninsuredMaxValue: string,
            schema: NumberSchema
        ) => {
            const originallyInsured =
                insuranceQuestionOriginallyInsured === 'YES';

            const normalizedInsuredMaxAmortization = numberNormalizer(
                amortizationInsuredMaxValue
            );

            const normalizedUninsuredMaxAmortization = numberNormalizer(
                amortizationUninsuredMaxValue
            );

            if (originallyInsured) {
                return schema.max(
                    normalizedInsuredMaxAmortization,
                    i18n._({
                        id: 'errors.maxAmortizationPeriodValue',
                        values: { value: normalizedInsuredMaxAmortization },
                    })
                );
            }

            return schema.max(
                normalizedUninsuredMaxAmortization,
                i18n._({
                    id: 'errors.maxAmortizationPeriodValue',
                    values: { value: normalizedUninsuredMaxAmortization },
                })
            );
        }
    );

/**
 * This function returns the schema for the subject property amortization months
 * @param i18n I18n instance
 * @returns The schema for the subject property amortization months
 */
export const getSubjectPropertyAmortizationMonthsSchema = (i18n: I18n) =>
    getBaseAmortizationMonthsSchema(i18n).when(
        [
            'currentAmortizationYears',
            'insuranceQuestionOriginallyInsured',
            '$amortizationInsuredMaxValue',
            '$amortizationUninsuredMaxValue',
        ],
        // @ts-ignore
        (
            currentAmortizationYears: number,
            insuranceQuestionOriginallyInsured: 'YES' | 'NO',
            amortizationInsuredMaxValue: string,
            amortizationUninsuredMaxValue: string,
            schema: NumberSchema
        ) => {
            const originallyInsured =
                insuranceQuestionOriginallyInsured === 'YES';

            const normalizedInsuredMaxAmortization = numberNormalizer(
                amortizationInsuredMaxValue
            );

            const normalizedUninsuredMaxAmortization = numberNormalizer(
                amortizationUninsuredMaxValue
            );

            const amortizationYearsWithinLimit =
                currentAmortizationYears < normalizedInsuredMaxAmortization;

            if (originallyInsured && !amortizationYearsWithinLimit) {
                return schema.max(
                    0,
                    i18n._({
                        id: 'errors.maxAmortizationPeriodValue',
                        values: { value: normalizedInsuredMaxAmortization },
                    })
                );
            }

            if (!originallyInsured && !amortizationYearsWithinLimit) {
                return schema.max(
                    0,
                    i18n._({
                        id: 'errors.maxAmortizationPeriodValue',
                        values: { value: normalizedUninsuredMaxAmortization },
                    })
                );
            }

            return schema;
        }
    );
