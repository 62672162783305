import * as yup from 'yup';

import type { I18n } from '@lingui/core';

export const stringRequired = (
    i18n: I18n,
    message?: string,
    values?: Record<string, any>
) =>
    yup
        .string()
        .required(i18n._({ id: message || 'error.fieldRequired', values }));

export const stringLength = (i18n: I18n, len: number) =>
    yup
        .string()
        .test(
            'len',
            i18n._(`error.numberLength`, { len }),
            (val) => String(val).length === len
        );
