import type { ApplicantInfo } from 'types/applicant';
import type {
    ApplicationEntityWithId,
    ApplicationProblemAugmented,
} from 'types/problems';

export const compareForApplicant =
    (applicant?: ApplicantInfo) =>
    (problems: ApplicationProblemAugmented[] = []) => {
        const isFound = problems.find(
            (problem) =>
                (problem?.entity as ApplicantInfo)?.applicantId ===
                applicant?.applicantId
        );

        return isFound ? true : false;
    };

export const compareForId =
    (entity: ApplicationEntityWithId) =>
    (problems: ApplicationProblemAugmented[] = []) =>
        problems.find((problem) => problem?.entityId === entity?.id)
            ? true
            : false;

export const compareForAddress =
    (address: any, namePrefix?: string) =>
    (problems: ApplicationProblemAugmented[] = []) => {
        return Boolean(problems?.length);
    };

export const compareForFieldName =
    (compareForFieldName: any /* TODO Employment, ... */) =>
    (problems: ApplicationProblemAugmented[] = []) =>
        problems.find((problem) => problem?.field.name === compareForFieldName)
            ? true
            : false;
