import React, { forwardRef, useCallback, useState, useMemo } from 'react';

import { Next } from 'grommet-icons';
import { components } from 'react-select';
import { Box, Flex } from 'reflexbox/styled-components';

import { AsyncSelectUI } from 'components/forms/react-select';
import canadaPost from 'libs/canadaPost';
import { useI18n } from 'providers/i18n/use-i18n';
import { Address } from 'types/address';

import type { RetrieveItem } from 'libs/canadaPost';
import type { Props as SelectProps, SelectInstance } from 'react-select';

const getOptions = async (inputValue, country = 'CA', requestId?: string) => {
    if (!inputValue || inputValue?.length < 3) {
        return [];
    }
    const { Items: items } = await canadaPost.find(
        inputValue,
        country as 'CA',
        requestId
    );

    const addresses: SelectOption[] = (items || []).map((item) => {
        return {
            ...item,
            value: item.Id,
            label: `${item.Text}, ${item.Description}`,
        };
    });

    return addresses;
};

enum AutoCompleteActions {
    MenuClose = 'menu-close',
    SelectOption = 'select-option',
}
interface SelectOption {
    value: string;
    label: string;
    Cursor: number;
    Description: string;
    Highlight: string;
    Id: string;
    Next: string;
    Text: string;
}

interface Props {
    gridArea?: string;
    country?: Address['countryCode'];
    onRetrieve?: (items: RetrieveItem[]) => void;
}

export const AddressAutocomplete = forwardRef(
    (
        { gridArea, country = 'CA', onRetrieve }: Props,
        ref: React.ForwardedRef<SelectInstance>
    ) => {
        const { i18n } = useI18n();
        const [options, setOptions] = useState<SelectOption[]>([]);
        const [selectedValue, setSelectedValue] = useState('');
        const [isMenuOpenState, setIsMenuOpenState] = useState(false);
        const [activeState, setActiveState] = useState<boolean>(false);

        const loadOptions = useCallback(
            async (inputValue) => {
                if (!inputValue || inputValue?.length < 3) {
                    return [];
                }

                const options = await getOptions(inputValue, country);

                setOptions(options);

                return options;
            },
            [country]
        );

        const handleIsMenuOpenState = (_value: string, eventAction) => {
            const { action } = eventAction;
            if (action === AutoCompleteActions.MenuClose)
                setIsMenuOpenState(false);
        };

        const handleInputChange: SelectProps['onChange'] = async (
            inputValue: SelectOption,
            eventAction
        ) => {
            const { action } = eventAction;
            setActiveState(true);

            if (
                action === AutoCompleteActions.SelectOption &&
                inputValue?.Next === 'Find'
            ) {
                const options = await getOptions(
                    inputValue.Text,
                    country,
                    inputValue.Id
                );

                setOptions(options);
            }

            if (
                action === AutoCompleteActions.SelectOption &&
                inputValue?.Next === 'Retrieve'
            ) {
                const { Items: items } = await canadaPost.retrieve(
                    inputValue.Id
                );

                onRetrieve && onRetrieve(items);

                setSelectedValue(items?.[0].Line1);
                setIsMenuOpenState(false);
            }
        };

        return (
            <Box css={{ gridArea }}>
                <AsyncSelectUI
                    ref={ref}
                    className="rs-ac"
                    classNamePrefix="rs-ac"
                    name="address-autocomplete"
                    cacheOptions
                    defaultOptions={options}
                    isClearable
                    isSearchable
                    loadOptions={loadOptions}
                    onChange={handleInputChange}
                    onInputChange={handleIsMenuOpenState}
                    closeMenuOnSelect={false}
                    menuIsOpen={isMenuOpenState}
                    components={{ Option }}
                    placeholder={i18n._('searchAddress')}
                    // small hack to force rerender and fix the `hasValue` on a uncontrolled Select UI component
                    defaultValue={selectedValue}
                    onFocus={() => {
                        setIsMenuOpenState(true);
                        setActiveState(true);
                    }}
                    // small hack to show small placeholder when we load a list of addresses
                    isActiveState={activeState}
                />
            </Box>
        );
    }
);

const Option = ({ children, ...rest }: any) => {
    const showArrow = useMemo(() => {
        return rest.data?.Next === 'Find';
    }, [rest.data]);

    return (
        <components.Option {...rest}>
            <Flex justifyContent="space-between">
                <Box>{children}</Box>
                {showArrow && (
                    <Box>
                        <Next />
                    </Box>
                )}
            </Flex>
        </components.Option>
    );
};

AddressAutocomplete.displayName = 'AddressAutocomplete';
