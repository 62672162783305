import * as yup from 'yup';

import { stringRequired } from './string';

import type { I18n } from '@lingui/core';

export const getAddressSchema = (i18n: I18n) =>
    yup.object().shape({
        unit: yup.string().optional(),
        streetNumber: stringRequired(i18n),
        street: yup.string().required(i18n._({ id: 'error.fieldRequired' })),
        city: yup.string().required(i18n._({ id: 'error.fieldRequired' })),
        countryCode: stringRequired(i18n),
        stateCode: yup.string().when('countryCode', {
            is: (countryCode: string) => ['CA', 'US'].includes(countryCode),
            then: yup.string().required(i18n._({ id: 'error.fieldRequired' })),
            otherwise: yup.string().optional(),
        }),
        postalCode: yup.string().when(
            ['countryCode'], // @ts-ignore
            (countryCode, schema) => {
                if (countryCode === 'CA') {
                    return schema
                        .matches(
                            /^(?:(?:[ABCEGHJKLMNPRSTVXY][0-9][A-Z] ?[0-9][A-Z][0-9]))$/i,
                            i18n._({ id: 'error.invalidPostalCode' })
                        )
                        .required();
                }

                if (countryCode === 'US') {
                    return schema
                        .matches(
                            /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                            i18n._({ id: 'error.invalidPostalCode' })
                        )
                        .required();
                }

                return schema.emptyAsUndefined();
            }
        ),
    });

export const getPartialAddressSchema = (i18n: I18n) =>
    yup.object().shape({
        unit: yup.string().optional(),
        streetNumber: yup.string().optional(),
        street: yup.string().optional(),
        city: yup.string().optional(),
        countryCode: yup.string().nullable().optional(),
        stateCode: yup.string().nullable().optional(),
        postalCode: yup.string().when('countryCode', (countryCode, schema) => {
            if (countryCode === 'CA') {
                return schema.matches(
                    /^(?:(?:[ABCEGHJKLMNPRSTVXY][0-9][A-Z] ?[0-9][A-Z][0-9]))$/i,
                    {
                        message: i18n._({ id: 'error.invalidPostalCode' }),
                        excludeEmptyString: true,
                    }
                );
            }

            if (countryCode === 'US') {
                return schema.matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
                    message: i18n._({ id: 'error.invalidPostalCode' }),
                    excludeEmptyString: true,
                });
            }

            return schema.emptyAsUndefined();
        }),
    });

export const getPartialSubjectPropertyAddressSchema = (i18n: I18n) =>
    yup.object().shape({
        unit: yup.string().optional(),
        streetNumber: yup.string().optional(),
        street: yup.string().optional(),
        city: yup.string().optional(),
        countryCode: stringRequired(i18n).emptyAsUndefined(),
        stateCode: yup.string().when('countryCode', {
            is: (countryCode: string) => ['CA', 'US'].includes(countryCode),
            then: yup
                .string()
                .required(i18n._({ id: 'error.fieldRequired' }))
                .emptyAsUndefined(),
            otherwise: yup.string().optional(),
        }),
        postalCode: yup.string().when(
            ['countryCode'], // @ts-ignore
            (countryCode, schema) => {
                if (countryCode === 'CA') {
                    return schema
                        .matches(
                            /^(?:(?:[ABCEGHJKLMNPRSTVXY][0-9][A-Z] ?[0-9][A-Z][0-9]))$/i,
                            i18n._({ id: 'error.invalidPostalCode' })
                        )
                        .required();
                }

                if (countryCode === 'US') {
                    return schema
                        .matches(
                            /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                            i18n._({ id: 'error.invalidPostalCode' })
                        )
                        .required();
                }

                return schema
                    .required(i18n._({ id: 'error.invalidPostalCode' }))
                    .emptyAsUndefined();
            }
        ),
    });

export const getAddressCountryStateSchema = (i18n: I18n) =>
    yup.object().shape({
        countryCode: stringRequired(i18n),
        stateCode: yup.string().required(i18n._({ id: 'error.fieldRequired' })),
    });
