import yup from './yup-extended';

import type { I18n } from '@lingui/core';

export const numberRequired = (i18n: I18n) =>
    yup.number().required(i18n._({ id: 'error.fieldRequired' }));

export const minMoneyOf = (i18n: I18n, minValue = 0) =>
    yup
        .number()
        .fromMoney()
        .isValueNaN()
        .nullable()
        .min(
            minValue,
            i18n._({
                id: 'error.valueEqualOrGreaterThan',
                values: { value: minValue },
            })
        );

export const minMoneyRequiredOf = (i18n: I18n, minValue = 0) =>
    moneyRequired(i18n)
        .isValueNaN()
        .nullable()
        .min(
            minValue,
            i18n._({
                id: 'error.valueEqualOrGreaterThan',
                values: { value: minValue },
            })
        );

export const moneyRequired = (i18n: I18n) =>
    yup
        .number()
        .fromMoney()
        .required(i18n._({ id: 'error.fieldRequired' }));

export const numberLength = (i18n: I18n, len: number) =>
    yup
        .number()
        .test(
            'len',
            i18n._(`error.numberLength`, { len }),
            (val) => String(val).length === len
        );

// export function moneyIsNotEmpty(this: yup.NumberSchema, msg: string) {
//     return this.test({
//         name: 'moneyNotEmpty',
//         message: msg,
//         test: (value) => {
//             console.log('moneyIsNotEmpty', value);
//             // return value ? value.replace('R$', '').trim() !== '0,00' : false;
//             return false;
//         },
//     });
// }

// https://github.com/jquense/yup/issues/312#issuecomment-745034006
// addMethod(date, 'moneyIsNotEmpty', moneyIsNotEmpty)

// import { NumberSchema } from 'yup'

// declare module 'yup' {
//   interface NumberSchema {
//     moneyIsNotEmpty(selectableDays: number[]): DateSchema
//   }
// }
