import axios from 'axios';

export type FindItem = {
    Cursor: number;
    Description: string;
    Highlight: string;
    Id: string;
    Next: string;
    Text: string;
};

export type RetrieveItem = {
    AdminAreaCode: string;
    AdminAreaName: string;
    Barcode: string;
    Block: string;
    BuildingName: string;
    BuildingNumber: string;
    City: string;
    Company: string;
    CountryIso2: string;
    CountryIso3: string;
    CountryIsoNumber: number;
    CountryName: string;
    DataLevel: string;
    Department: string;
    District: string;
    DomesticId: string;
    Id: string;
    Label: string;
    Language: string;
    LanguageAlternatives: string;
    Line1: string;
    Line2: string;
    Line3: string;
    Line4: string;
    Line5: string;
    Neighbourhood: string;
    POBoxNumber: string;
    PostalCode: string;
    Province: string;
    ProvinceCode: string;
    ProvinceName: string;
    SecondaryStreet: string;
    SortingNumber1: string;
    SortingNumber2: string;
    Street: string;
    SubBuilding: string;
    Type: string;
};

const isServer = typeof window === 'undefined';

const API_KEY = !isServer
    ? window?.config?.canadaPostKey
    : 'xy82-fk32-wd46-en97';

const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
};

const api = axios.create({
    baseURL: `https://ws1.postescanada-canadapost.ca`,
    headers: {
        ...DEFAULT_HEADERS,
    },
    timeout: 25000,
});

export const find = async (
    searchTerm: string,
    country: 'CA' | 'US' = 'CA',
    requestId?: string
) => {
    try {
        const { data } = await api.get<{ Items: FindItem[] }>(
            '/AddressComplete/Interactive/Find/v2.10/json3.ws',
            {
                params: {
                    Key: API_KEY,
                    SearchTerm: searchTerm,
                    Country: country,
                    MaxResults: 8,
                    MaxSuggestions: 8,
                    SearchFor: 'Everything',
                    LastId: requestId || undefined,
                },
            }
        );

        return data;
    } catch (error) {
        console.error('CANADA POST ERROR', error);
        return { Items: [] };
    }
};

export const retrieve = async (id: string) => {
    try {
        const { data } = await api.get<{ Items: RetrieveItem[] }>(
            '/AddressComplete/Interactive/Retrieve/v2.10/json3.ws',
            {
                params: {
                    Key: API_KEY,
                    Id: id,
                },
            }
        );

        return data;
    } catch (error) {
        console.error('CANADA POST ERROR', error);
        return { Items: [] };
    }
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { find, retrieve };
