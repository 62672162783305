import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from 'reflexbox/styled-components';

import { AddressAutocomplete } from 'components/address-autocomplete';
import { EditableCell } from 'components/editable';
import { Grid } from 'components/grid/grid';
import { Text } from 'components/text/text';
import {
    COUNTRIES_OPTIONS,
    PROVINCES_OPTIONS,
    US_STATES_OPTIONS,
} from 'constants/contries-states';
import { useI18n } from 'providers/i18n/use-i18n';
import { formatAddress, formatAddressNotFound } from 'utils';
import { compareForAddress } from 'utils/validations/comparators';

import type { RetrieveItem } from 'libs/canadaPost';
import type { MenuPlacement } from 'react-select';
import type { Address } from 'types/address';

type AddressEditableProps = {
    isEditing?: boolean;
    isFound?: boolean;
    disabled?: boolean;
    hideAutoComplete?: boolean;
    address: Address;
    label?: string;
    namePrefix: string;
    required?: boolean;
    menuPlacement?: MenuPlacement;
    isSubjectProperty?: boolean;
    usesPartialSaving?: boolean;
    readonly?: boolean;
    sectionProblems?: any;
    gridGap?: React.CSSProperties['gap'];
    gridTemplateColumns?: React.CSSProperties['gridTemplateColumns'];
} & React.HTMLAttributes<HTMLDivElement>;

export const AddressEditable = ({
    isEditing,
    isFound = true,
    disabled = false,
    hideAutoComplete = false,
    address,
    label = 'propertyAddress',
    namePrefix = '',
    required = false,
    menuPlacement = 'auto',
    isSubjectProperty = false,
    usesPartialSaving = false,
    readonly,
    gridGap = 10,
    gridTemplateColumns = 'repeat(auto-fill, minmax(200px, 1fr))',
    className,
}: AddressEditableProps) => {
    const { i18n } = useI18n();

    const isAddressComplete =
        !!address?.city && !!address?.street && !!address?.streetNumber;

    return (
        <Grid
            className={className}
            gridGap={isEditing ? gridGap : 'var(--spacing-0)'}
            gridAutoRows="auto"
            gridTemplateColumns={gridTemplateColumns}
        >
            {!isEditing && !isAddressComplete && isFound && (
                <Text
                    fontWeight="400"
                    color="red"
                    tx="addressFieldsIncomplete"
                />
            )}
            {!isEditing && (
                <EditableCell
                    isEditing={false}
                    name={`${namePrefix}formattedAddress`}
                    fieldType="readonly"
                    label={i18n._(label)}
                    value={
                        isFound
                            ? formatAddress(address)
                            : formatAddressNotFound(address)
                    }
                    variant="primary"
                    required={required}
                    isFieldInvalidCompareFn={compareForAddress(
                        address,
                        namePrefix
                    )}
                    css={{
                        gridColumn: '1 / -1',
                    }}
                />
            )}
            {isEditing && (
                <EditingFields
                    namePrefix={namePrefix}
                    address={address}
                    menuPlacement={menuPlacement}
                    isFound={isFound}
                    disabled={disabled}
                    hideAutoComplete={hideAutoComplete}
                    required={required}
                    usesPartialSaving={usesPartialSaving}
                    isSubjectProperty={isSubjectProperty}
                    readonly={readonly}
                />
            )}
        </Grid>
    );
};

type EditingFieldsProps = {
    namePrefix: string;
    address: Address;
    hideAutoComplete?: boolean;
    isFound: boolean;
    disabled: boolean;
    menuPlacement: MenuPlacement;
    required: boolean;
    isSubjectProperty: boolean;
    usesPartialSaving: boolean;
    readonly: boolean;
};

const EditingFields = ({
    namePrefix,
    address,
    isFound,
    disabled,
    hideAutoComplete,
    menuPlacement,
    required,
    isSubjectProperty,
    usesPartialSaving,
    readonly,
}: EditingFieldsProps) => {
    const { i18n } = useI18n();
    const methods = useFormContext();

    const countryCode = useWatch({
        name: `${namePrefix}countryCode`,
        defaultValue: address?.countryCode || 'CA',
    });

    const handleOnRetrieve = (items: RetrieveItem[]) => {
        const selectedAddress = items?.[0];

        methods.setValue(
            `${namePrefix}countryCode`,
            selectedAddress.CountryIso2
        );
        methods.setValue(`${namePrefix}city`, selectedAddress.City);
        methods.setValue(`${namePrefix}postalCode`, selectedAddress.PostalCode);
        methods.setValue(
            `${namePrefix}stateCode`,
            selectedAddress.ProvinceCode
        );
        methods.setValue(`${namePrefix}unit`, selectedAddress.SubBuilding);
        methods.setValue(
            `${namePrefix}streetNumber`,
            selectedAddress.BuildingNumber
        );
        methods.setValue(`${namePrefix}street`, selectedAddress.Street);
    };

    return (
        <>
            {isFound && (
                <>
                    {!hideAutoComplete && !readonly && (
                        <Box
                            css={{
                                gridColumn: '1 / -1',
                            }}
                        >
                            <AddressAutocomplete
                                onRetrieve={handleOnRetrieve}
                                country={countryCode}
                            />
                        </Box>
                    )}
                    <EditableCell
                        isEditing={!readonly}
                        disabled={disabled}
                        name={`${namePrefix}unit`}
                        fieldType="text"
                        label={i18n._(`unit`)}
                        value={address?.unit || ''}
                    />
                    <EditableCell
                        isEditing={!readonly}
                        disabled={disabled}
                        name={`${namePrefix}streetNumber`}
                        fieldType="text"
                        label={i18n._(`streetNumber`)}
                        value={address?.streetNumber || ''}
                        required={required && !isSubjectProperty}
                    />
                    <EditableCell
                        isEditing={!readonly}
                        disabled={disabled}
                        name={`${namePrefix}street`}
                        fieldType="text"
                        label={i18n._(`streetNameAndType`)}
                        value={address?.street || ''}
                        required={required && !isSubjectProperty}
                    />
                    <EditableCell
                        isEditing={!readonly}
                        disabled={disabled}
                        name={`${namePrefix}city`}
                        fieldType="text"
                        label={i18n._(`City`)}
                        value={address?.city || ''}
                        required={required && !isSubjectProperty}
                    />
                </>
            )}
            <EditableCell
                isEditing={!readonly}
                disabled={disabled}
                name={`${namePrefix}countryCode`}
                fieldType="select"
                label={i18n._(`Country`)}
                value={countryCode || 'CA'}
                options={COUNTRIES_OPTIONS(i18n)}
                isClearable={usesPartialSaving}
                required={required}
            />
            <EditableCell
                isEditing={!readonly}
                disabled={disabled}
                name={`${namePrefix}stateCode`}
                fieldType="select"
                isClearable={usesPartialSaving}
                label={countryCode === 'US' ? 'state' : 'stateCode'}
                value={address?.stateCode || ''}
                options={
                    countryCode === 'US'
                        ? US_STATES_OPTIONS(i18n)
                        : PROVINCES_OPTIONS(i18n)
                }
                menuPlacement={menuPlacement}
                required={required && countryCode}
            />
            {isFound && (
                <EditableCell
                    isEditing={!readonly}
                    disabled={disabled}
                    name={`${namePrefix}postalCode`}
                    fieldType="postalCode"
                    countryCode={countryCode || 'CA'}
                    label={countryCode === 'US' ? 'zipCode' : 'postalCode'}
                    value={address?.postalCode || ''}
                    required={required && countryCode}
                />
            )}
        </>
    );
};
